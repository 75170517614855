import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'Home',
			component: () => import('@/module/home/Home.vue'),
			meta: {
				keepAlive: false,
				noLogin: true
			}
	},
	{
		path: '/noticeList',
    name: 'noticeList',
		component: () => import('@/module/notice/noticeList.vue'),
		meta: {
			keepAlive: false,
			name: '公告列表'
		}
	},
	{
		path: '/noticeDetail',
    name: 'noticeDetail',
		component: () => import('@/module/notice/noticeDetail.vue'),
		meta: {
			keepAlive: false,
			noLogin: true
		}
	},
	{
		path: '/closeFittingList',
    name: 'closeFittingList',
		component: () => import('@/module/closeFitting/closeFittingList.vue'),
		meta: {
			keepAlive: false,
			name: '贴身毛列表'
		}
	},
	{
		path: '/closeFittingDetail',
    name: 'closeFittingDetail',
		component: () => import('@/module/closeFitting/closeFittingDetail.vue'),
		meta: {
			keepAlive: false,
			noLogin: true
		}
	},
	{
		path: '/technology/inlet',
    name: 'technologyInlet',
		component: () => import('@/module/technology/technologyInlet.vue'),
		meta: {
			keepAlive: false,
			noLogin: true
		}
	},
	{
		path: '/technology/ways',
    name: 'technologyWays',
		component: () => import('@/module/technology/technologyWays.vue'),
		meta: {
			keepAlive: false,
			noLogin: true
		}
	},
	{
		path: '/technology/setting',
    name: 'technologySetting',
		component: () => import('@/module/technology/technologySetting.vue'),
		meta: {
			keepAlive: false,
			noLogin: true,
			name: ''
		}
	},
];

export default new Router({
	routes,
	mode: 'history'
});
