import Vue from 'vue'
import ElementUI from 'element-ui';
import './assets/icons';
import 'element-ui/lib/theme-chalk/index.css';
import components from './components';
import router from './router';
import store from './store';
import tools from './config/tools.js';
import {getRoleButtonByMenu} from "./components/home/api"
import App from './App.vue'
import './assets/styles/reset.css'
import './assets/styles/border.css'

// components.forEach(item => Vue.component(item.name, item.component));  //注册全局组件
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$tools = tools  //全局方法

// if(process.env.VUE_APP_ENV_TYPE=='dev') { //是否是本地开发环境
// 	tools.setStore('access_token', `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImEzck1VZ01Gdjl0UGNsTGE2eUYzekFrZnF1RSIsImtpZCI6ImEzck1VZ01Gdjl0UGNsTGE2eUYzekFrZnF1RSJ9.eyJpc3MiOiJodHRwczovL3Rzc28uY2xvdWRob3RlbHMuY24iLCJhdWQiOiJodHRwczovL3Rzc28uY2xvdWRob3RlbHMuY24vcmVzb3VyY2VzIiwiZXhwIjoxNjQ3ODM2NDI2LCJuYmYiOjE2NDc4MzI4MjYsImNsaWVudF9pZCI6IjEwMDAwMDU3Iiwic2NvcGUiOiJvcGVuaWQiLCJzdWIiOiJ7XCJ1c2VyaWRcIjpcInhmemhhaVwiLFwiaG90ZWxjZFwiOlwiXCIsXCJ1c2VybmFtZVwiOlwiXCIsXCJob3RlbG5hbWVcIjpcIlwiLFwiQ2l0eUdyb3VwSURcIjpcIlwifSIsImF1dGhfdGltZSI6MTY0NzgzMjgyNiwiaWRwIjoiaWRzcnYiLCJhbXIiOlsicGFzc3dvcmQiXX0.AL1dUEchFFc7H6xa3E4fC8YmyBJTjhLBLRWEv5LBSK3ONaxHONBF2dRRyaQC5fUASHsH0Krjfvivt0rKtBSz1njmGMz3y2a3gFpgozRxkIwVceCXCb_zJq1v06vNZSdAm9Yfaq8EGlJYfeWRwBdep-YNsRj1fXbTDbfPKKtaFuxC_QhydOggrKAzdyGRx32cbTXnVKPWCl5_3CqXSOdjbuLbEW1kMOzQRJBSCyvd-MlIQyR8SV3dw1hRo0goEBfdV9HTbZp6Cq9W-haonk8UpMgZEZvHf1CvReH-8kDUZFPrtYrQ4oTH7TQLan2YWprlUx923ZwKZgMCscK6IMNPvQ`)
// }



// Vue.directive('select-scroll-loadMore', {
// 	bind(el, binding) {
// 		const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown__wrap .el-select-dropdown__wrap')
// 		SELECTWRAP_DOM.addEventListence('scroll', function() {
// 			const condition = this.scrollHeight - this.scrollTop <= this.clienHeight
// 			if(condition) {
// 				binding.value()
// 			}
// 		})
// 	}
// })
// router.beforeEach((to, from, next) => {
// 	if(to.meta.noLogin) {
// 		next();
// 	} else {
// 		if (tools.getStore('access_token')) {
// 			let name=to.name;
// 			console.log(name);
// 			if(name=="organization-brand-change"){
// 				name="organization-brand"
// 			}else if(name=="staff-daidianTransfer"){
// 				name="staff-daidian"
// 			}
// 			getRoleButtonByMenu({menuName:name}).then((res)=>{
// 				if(res.code==0){
// 					let obj={}
// 					res.data.forEach((item)=>{
// 						obj[item.buttonName]=item.isJurisdiction;
// 					})
// 					store.commit('UPDATE_RoleButtonByMenu',obj);
// 					next();
// 				}else{
// 					ElementUI.Message.error(res.msg)
// 				}
// 			}).catch((err)=>{
// 				ElementUI.Message.error(err);
// 			});
			
			
			
// 		} else {
// 			tools.toLoginPage()
// 			// next({
// 			// 	path: "/login"
// 			// });
// 		}
// 	}
	
// });
new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')
