import oidcClient from 'oidc-client';

// 新版sso 2022-3-14
let testconfig = {
  authority: process.env.VUE_APP_LOGIN_URL,
  client_id: "10000057",
  redirect_uri: process.env.VUE_APP_REDIRECT_URI, //重定向地址
  response_type: "id_token token", //表示授权类型，必选项
  scope: "sso-api openid profile", //表示申请的权限范围，可选项
  clockSkew: 0,
  post_logout_redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  //启用静默刷新token 默认不需要启用
  // silent_redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  // automaticSilentRenew: true
};
let mgr = new oidcClient.UserManager(testconfig);

/**
 * 获取localStorage
 */
const logout = () => {
	setStore('access_token', '');
	setStore('id_token', '');
	mgr.signoutRedirect();
};

/**
 * 跳转至登录界面
 */

// function rand() {
// 	return (Date.now() + "" + Math.random()).replace(".", "");
// }


const toLoginPage = () => {
	setStore('access_token', '');
	setStore('id_token', '');
	mgr.signinRedirect({ state: window.location.href });
};

/**
 * 获取localStorage
 */
const getStore = name => {
	if (!name) return;
	return window.localStorage.getItem(name);
};

/**
 * 存储localStorage
 */
const setStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
};

/**
 * 删除localStorage
 */
const removeStore = name => {
	if (!name) return;
	window.localStorage.removeItem(name);
};

/**
 * 获取cookie
 */

const getCookie = name => {
	var arr,
		reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
	if ((arr = document.cookie.match(reg))) {
		return arr[2];
	} else {
		return null;
	}
};

/**
 * 删除cookie
 */

const delCookie = name => {
	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
};
const getDate = (val, type='-', noNew=false) => {
	let date = new Date();
	if (val){
		date = new Date(val);
	} else if(noNew) {
		return ''
	}
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;
	return `${year}${type}${month}${type}${day}`;
}
/**
 * 设置 cookie 值的函数
 */

const setCookie = (cname, cvalue, exdays) => {
	var d = new Date();
	d.setTime(d.getTime() + exdays);
	var expires = "expires=" + d.toGMTString();
	document.cookie = cname + "=" + cvalue + "; " + expires;
}
const Base64 = require('js-base64').Base64
//防抖
const debounce=(fn,wait)=>{
	var timeout;
	return function(){
		var _this=this;
		var args=arguments;
		clearTimeout(timeout);
		timeout=setTimeout(()=>{
			fn.apply(_this,args);
		},wait)
	}
}
const formatDate=(date, fmt = "YYYY-MM-dd")=>{
	if (Object.prototype.toString.call(date) !== "[object Date]") {
	  let tempDate = date;
	  if (typeof tempDate == "string") {
		tempDate = date.replace(/-/g, "/");
	  }
	  date = new Date(tempDate);
	}
	var o = {
	  "M+": date.getMonth() + 1, //月份
	  "d+": date.getDate(), //日
	  "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
	  "H+": date.getHours(), //小时
	  "m+": date.getMinutes(), //分
	  "s+": date.getSeconds(), //秒
	  "q+": Math.floor((date.getMonth() + 3) / 3), //季度
	  S: date.getMilliseconds(), //毫秒
	};
	if (/(y+)/i.test(fmt)) {
	  fmt = fmt.replace(
		RegExp.$1,
		(date.getFullYear() + "").substr(4 - RegExp.$1.length)
	  );
	}
	for (var k in o) {
	  if (new RegExp("(" + k + ")").test(fmt)) {
		fmt = fmt.replace(
		  RegExp.$1,
		  RegExp.$1.length == 1
			? o[k]
			: ("00" + o[k]).substr(("" + o[k]).length)
		);
	  }
	}
	return fmt;
  }
  //通过文件下载url拿到对应的blob对象
const getBlob=(url)=>{
	return new Promise(resolve => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response);
			}
		};
		xhr.send();
	});
}
//下载文件
const saveAs=(blob, filename)=>{
	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = filename;
	link.click();
};
//日期格式化
export default {
	getDate,
	Base64,
	toLoginPage,
	removeStore,
	getCookie,
	delCookie,
	setCookie,
	getStore,
	setStore,
	logout,
	debounce,
	formatDate,
	getBlob,
	saveAs
}
