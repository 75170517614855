import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isCollapse: false,
		menuName: '',
		roleButtonByMenu:null,
	},
	mutations: {
		UPDATE_NAV_COLLAPSE(state, payload) {
			state.isCollapse = payload
		},
		UPDATE_NAV_MENUNAME(state, menuName) {
			state.menuName = menuName
		},
		UPDATE_RoleButtonByMenu(state,val){
			state.roleButtonByMenu=val;
		}
	},
	actions: {},
	modules: {}
})
