import axios from 'axios'
import {
	Loading
} from 'element-ui';
import tools from './tools.js';

let num = 0
let loading = null
const service = axios.create({
	baseURL: process.env.VUE_APP_REQUEST_URL,
	timeout: 90000
})
// request拦截器
service.interceptors.request.use(config => {
	if (num == 0&&config.isLoading) {
		loading = Loading.service({
			lock: true,
			text: '加载中',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
	}
	num++
	if (tools.getStore('access_token')) {
		config.headers['Authorization'] = 'Bearer ' + tools.getStore('access_token')
	}
	return config
}, error => {
	return Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	response => {
		if (num == 1) {
			if(loading) loading.close();
			num = 0
		} else {
			num--
		}
		if (response.data.code === 401) {
			tools.toLoginPage();
			return Promise.resolve('')
		} else if (response.data.code === 411) {
			tools.logout();
			return Promise.resolve(response.data)
		} else{
			return response.data
		}
	},
	error => {
		if (num == 1) {
			loading.close();
			num = 0
		} else {
			num--
		}
		if(error.response.status==401) {
			tools.toLoginPage();
			return Promise.reject('')
		} else if(error.response.status==411) {
			tools.logout();
			return Promise.reject(error.response.data)
		} else {
			return Promise.reject(error.response.data.msg?error.response.data:{msg: error.message, error: error.message})
		}
	}
)
const fetch = {
	get: (url, params, isLoading=true) => {
		return service({
			method: 'get',
			url: url,
			isLoading: isLoading,
			params: params
		})
	},
	post: (url, params, isLoading=true) => {
		return service({
			method: 'post',
			url: url,
			isLoading: isLoading,
			data: params
		})
	},
	download: (url, params, isLoading=true) => {
		return service({
			method: 'post',
			url: url,
			data: params,
			isLoading: isLoading,
			responseType: 'blob'
		})
	}
}
export default fetch
