<template>
  <div id="app">
    <div class="head">
      <img class="head-img" src="./assets/img/icon.png" alt="">
      <div class="head-cont">
        <span @click="$router.push('/')">首页</span>
        <div class="line"></div>
        <span>画廊</span>
        <div class="line"></div>
        <span>关于我们</span>
        <div class="line"></div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body{
  width: 100%;
  height: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  background: url(./assets/img/bg.jpg)center;
  position: relative;
  overflow: hidden;
  /* width: 100%;
  height: 100%;
  position: fixed; */
}
.head{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #24242D;
}
.head-img{
    width: 40px;
    height: 40px;
    margin-right: 35px;
}
.head-cont{
    display: flex;
    align-items: center;
}
.head-cont span{
    font-size: 20px;
    color: #636378;
    margin-left: 20px;
    cursor: pointer;
}
.head-cont span:hover{
    color: #B7B7DE;
}
.line{
    width: 2px;
    height: 20px;
    background: #E9B96B;
    margin-left: 20px;
}
.el-scrollbar{
  background: #101015;
}
.el-select-dropdown{
  border-color: #6A778B;
}
.el-select-dropdown__item{
  color: #ffffff;
  font-size: 30px;
  font-weight: 300;
  height: 80px;
  line-height: 80px;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover{
  background: #49D6FF;
}
.el-popper[x-placement^=bottom] .popper__arrow::after{
  border-bottom-color: #101015;
}
.el-popper[x-placement^=top] .popper__arrow::after{
  border-top-color: #101015;
}
.el-popper[x-placement^=bottom] .popper__arrow{
  border-bottom-color: #6A778B;
}
</style>
